import Button from "components/common/buttons/buttons";
import { Forward } from "components/common/Icons";
import { useRouter } from "next/router";
import { useState } from "react";
import { TYPO } from "styles/Typo";
import IOSUserDescTemplete from "../../KB/IOSUserDesc";
import TopHeader from "components/common/header";
import { useIosFixedBottomButton } from "utils/ios";

function NoConnectableAccount({
  isMobile,
  backHandler,
}: {
  isMobile: boolean;
  backHandler: any;
}) {
  const router = useRouter();
  const ts = router.query.ts;

  const [isIosUserConfirmPage, setIsIosUserConfirmPage] = useState(false);
  useIosFixedBottomButton();

  //   if (isIosUser && isIosUserConfirmPage) {
  //     return (
  //       <main className={"min-w-[360px] max-w-[800px] h-auto bg-white w-screen"}>
  //         <IOSUserDescTemplete
  //           backHandler={() => {
  //             setIsIosUserConfirmPage(false);
  //           }}
  //           confirmHandler={() => {
  //             router.push("/kb/newaccount");
  //           }}
  //         />
  //       </main>
  //     );
  //   }

  return (
    <div>
      <TopHeader
        clickHandler={() => {
          if (backHandler) {
            backHandler();
          }
        }}
      />
      <div className="pt-6 px-6 flex flex-col gap-4">
        <span className={`${TYPO.TYPO3_SB} text-gray90 whitespace-pre-line`}>
          연결 가능한 계좌가 없어요
          <br />
          계좌를 새로 만들까요?
        </span>
        <div
          className="py-4 flex justify-between cursor-pointer"
          onClick={() => {
            window.open("https://open.kakao.com/o/sU32v7ff", "_blank");
          }}
        >
          <span className={`${TYPO.TYPO5_1_RG} text-gray70`}>
            내 계좌가 뜨지 않나요?
          </span>
          <Forward fill="gray40" height={16} width={16} />
        </div>
      </div>
      <div className="bg-white min-h-[400px]"></div>
      <div
        className={
          isMobile
            ? ` z-[99] bg-white w-full px-5 pt-4 pb-[30px] fixed bottom-0 left-0 !font-semibold `
            : `z-[99] bg-white w-[800px] px-5 pt-4 pb-[30px] fixed bottom-0 left-[50%] translate-x-[-50%] !font-semibold `
        }
      >
        <Button
          type="primary"
          clickHandler={() => {
            router.push(`/kb/newaccount?ts=${ts}`);
          }}
          size="lg"
          text={`다음`}
          width="w-full"
        />
      </div>
    </div>
  );
}

export default NoConnectableAccount;
